import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const ElectricityCart = ({electricityData, type, unit, onTimeRangeChange, initMonth, totalRead, timeStart, timeEnd}) => {
    const [timeRange, setTimeRange] = useState(initMonth);

    const handleTimeRangeChange = (e) => {
        setTimeRange(e.target.value);
        onTimeRangeChange(e.target.value);
    };

    const getData = () => {
        let last30DaysLabels = [];
        let last30DaysData = [];
        switch (type) {
            case 'Electricity':
                last30DaysLabels = [];
                last30DaysData = [];
                // for (let i = 29; i >= 0; i--) {
                //     last30DaysLabels.push(i + 1); // Get the day of the month
                //     last30DaysData.push(Math.floor(Math.random() * 15000)); // Random example data
                // }
                let groupMyMonth = {};
                electricityData?.groupedReadings?.map(read=>{
                    groupMyMonth[moment(read?.groupName).format('MMM')] = read?.reading
                    // last30DaysLabels.push(moment(read?.groupName).format('YY-MM-DD')); // Get the day of the month
                    // last30DaysData.push(read?.reading); // Random example data
                })
                let current = moment(timeStart).startOf('month'); // Start from the beginning of the start month

                while (current.isBefore(timeEnd, 'month') || current.isSame(timeEnd, 'month')) {
                    // months.push(current.format('MMM')); // You can adjust the format here
                    if(!groupMyMonth[current.format('MMM')]) {
                        groupMyMonth[current.format('MMM')] = 0;
                        last30DaysLabels.push(current.format('MMM')); 
                        last30DaysData.push(0); 
                    } else {
                        last30DaysLabels.push(current.format('MMM')); 
                        last30DaysData.push(groupMyMonth[current.format('MMM')]); 
                    }
                    current.add(1, 'months');
                }
                // for(let group in groupMyMonth) {
                //     console.log('group',group)
                //     last30DaysLabels.push(group); 
                //     last30DaysData.push(groupMyMonth[group]); 
                // }
                return {
                    data: last30DaysData,
                    labels: last30DaysLabels
                };
            default:
                last30DaysLabels = [];
                last30DaysData = [];
                // for (let i = 29; i >= 0; i--) {
                //     last30DaysLabels.push(i + 1); // Get the day of the month
                //     last30DaysData.push(Math.floor(Math.random() * 15000)); // Random example data
                // }
                electricityData?.map(read=>{
                    last30DaysLabels.push(read?.groupName); // Get the day of the month
                    last30DaysData.push(parseFloat(read?.reading)); // Random example data
                })
                console.log('data===>',last30DaysData, last30DaysLabels)
                return {
                    data: last30DaysData,
                    labels: last30DaysLabels
                };
        }
    };

    const { data: chartData, labels: chartLabels } = getData();
    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: '',
                data: chartData,
                backgroundColor: '#54558B', // Set the bar color here
                borderColor: '#54558B', // Set the border color here
                borderWidth: 1,
                barThickness: 10, // Set max column width here
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false, // Remove vertical grid lines
                },
            },
            y: {
                ticks: {
                    callback: (value) => {
                        return value >= 1000 ? `${value / 1000}k` : value;
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Completely hide the legend
            },
            title: {
                display: false,
                text: 'Energy Consumption Data',
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const { dataset, parsed } = tooltipItem;
                        const dateLabel = tooltipItem.label; // Assuming the label is the date
                        const value = parsed.y; // Get the Y value
                        return `${dateLabel}, ${value.toLocaleString()} kWh`; // Format the tooltip
                    },
                },
            },
        },
    };
    return (
        <div className='electCharWRapper'>
            <div className='elechEADWRp d-flex justify-content-between gap-5'>
                <div className='electLeft'>
                    <h2>{type}</h2>
                    <small>Usage</small>
                    <h3>{totalRead?.toLocaleString(2)} {unit}</h3>
                </div>
                <div className='electRgiht'>
                    <div className='dropdChangChart'>
                        <label>Timeframe</label>
                        {type == 'Electricity' && <select value={timeRange} onChange={handleTimeRangeChange}>
                            <option value="currentMonth">This month</option>
                            <option value="last3Months">Last 3 Months</option>
                            <option value="last6Months">Last 6 Months</option>
                            <option value="lastYear">Last 1 Year</option>
                            <option value="last3Year">Last 3 Year</option>
                        </select>}
                        {type != 'Electricity' && <select value={timeRange} onChange={handleTimeRangeChange}>
                            <option value="last3Months">Last 3 Months</option>
                            <option value="last6Months">Last 6 Months</option>
                            <option value="lastYear">Last 1 Year</option>
                            <option value="last3Year">Last 3 Year</option>
                        </select>}
                        
                    </div>

                </div>
            </div>
            <div className='chartWRpbox'>
                <Bar data={data} options={options} />
            </div>
        </div>
    )
}

export default ElectricityCart
