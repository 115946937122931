import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import ProgressBarMultiscore from '../progress-bar/progress-bar-multiscore';
import ProgressBar from '../progress-bar/progress-bar';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const Overview = ({building, electricityData, gasData, waterData, buildingId, oil1Data, oil2Data, steamData}) => {
    const [emissionsLimit, setEmissionsLimit]=useState(0);
    const [grade, setGrade]=useState('');
    useEffect(()=>{
        setEmissionsLimit(+(building?.carbonEmissions?.emissionsUsed?.toFixed(2)/building?.carbonEmissions?.emissionsLimit?.toFixed(2))*100)
        console.log('electricityData', electricityData)
        if(building?.currentEnergyStarGrade?.grade>85) {
            setGrade('A')
        } else if(building?.currentEnergyStarGrade?.grade>70) {
            setGrade('B')
        } else if(building?.currentEnergyStarGrade?.grade>55) {
            setGrade('C')
        } else{
            setGrade('D')
        }
    },[building])
    return (
        <div className='tabBodyWrp'>
            <div className='enery_carbon_wrp d-flex'>
                <div className='enery_wrp'>
                    <h4>Energy Star score</h4>
                    <ProgressBarMultiscore grade={grade} value={building?.currentEnergyStarGrade?.grade?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} labels={[
                        { name: 'D', partition: 55, color: '#ED4833', width: '55%' },
                        { name: 'C', partition: 70, color: '#F6B135', width: '15%' },
                        { name: 'B', partition: 85, color: '#F5D738', width: '15%' },
                        { name: 'A', partition: 100, color: '#39B085', width: '15%' }
                    ]} />

                </div>
                <div className='carbon_wrp'>

                    <h4>Carbon Emissions</h4>
                    <ProgressBar percent={emissionsLimit?.toFixed('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} value={building?.carbonEmissions?.emissionsUsed?.toLocaleString(2)} fine={building?.carbonEmissions?.current?.fine?.amount} total={building?.carbonEmissions?.emissionsLimit?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} unit='kgCo2e' />

                </div>
            </div>
            <div className='utilistWrp'>
                <div className='ulsitHead'>
                    <h4>Utilities this year</h4>
                </div>
                <Accordion>
                    {building.showElectricity && <Accordion.Item eventKey="0">
                        <Link style={{textDecoration:'none'}} to={`/electricity-details/${buildingId}`}>
                            <Accordion.Header>
                                <h4>Electricity</h4>
                                <h5>Usage</h5>
                                <h3>{electricityData?.total?.reading?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} kWh</h3>
                                                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg> */}

                            </Accordion.Header>
                        </Link>
                    </Accordion.Item>}
                    {building.showGas && <Accordion.Item eventKey="1">
                        <Link style={{textDecoration:'none'}} to={`/gas-details/${buildingId}`}>
                            <Accordion.Header>
                                <h4>Gas</h4>
                                <h5>Usage</h5>
                                <h3>{gasData?.total?.reading?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} CCF</h3>
                            </Accordion.Header>
                        </Link>
                    </Accordion.Item>}
                    {building.showWater && <Accordion.Item eventKey="2">
                        <Link style={{textDecoration:'none'}} to={`/water-details/${buildingId}`}>
                            <Accordion.Header>
                                <h4>Water</h4>
                                <h5>Usage</h5>
                                <h3>{waterData?.total?.reading?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} gal</h3>
                            </Accordion.Header>
                        </Link>
                    </Accordion.Item>}
                    {building.showOil1 && <Accordion.Item eventKey="3">
                        <Link style={{textDecoration:'none'}} to={`/oil1-details/${buildingId}`}>
                            <Accordion.Header>
                                <h4>Oil2</h4>
                                <h5>Usage</h5>
                                <h3>{oil1Data?.total?.reading?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} Gallon</h3>
                            </Accordion.Header>
                        </Link>
                    </Accordion.Item>}
                    {building.showOil2 && <Accordion.Item eventKey="4">
                        <Link style={{textDecoration:'none'}} to={`/oil2-details/${buildingId}`}>
                            <Accordion.Header>
                                <h4>Oil4</h4>
                                <h5>Usage</h5>
                                <h3>{oil2Data?.total?.reading?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} Gallon</h3>
                            </Accordion.Header>
                        </Link>
                    </Accordion.Item>}
                    {building.showSteam && <Accordion.Item eventKey="4">
                        <Link style={{textDecoration:'none'}} to={`/steam-details/${buildingId}`}>
                            <Accordion.Header>
                                <h4>Direct Steam</h4>
                                <h5>Usage</h5>
                                <h3>{steamData?.total?.reading?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} MLbs</h3>
                            </Accordion.Header>
                        </Link>
                    </Accordion.Item>}
                </Accordion>
            </div>
        </div>
    )
}

export default Overview
