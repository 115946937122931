import React, { useEffect, useState } from 'react'
import ElectricityCart from '../component/charts/electricityCart'
import { useQuery } from '@apollo/client';
import { ACCOUNT_QUERY, BUILDING_QUERY, UTILITIES_QUERY } from '../graphql/mutations';
import useApolloClient from '../apolloClient1';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import Moment from 'react-moment';
import { Spinner } from 'react-bootstrap';

const WaterDetails = () => {

    const { id } = useParams();
    const [token, setToken]=useState(false);
    const [timeStart, setTimeStart]=useState();
    const [timeEnd, setTimeEnd]=useState();
    const [totalElectricityRead, setTotalElectricityRead] = useState(0);
    const [initMonth, setInitMonth]=useState('lastYear');
    const [readings, setReadingData] = useState(0);

    useEffect(()=>{
        // const accountId = localStorage.getItem("accountId");
        let token = localStorage.getItem('token')
        setToken(token)
        let timeStart = moment().subtract(1, 'year').startOf('month');
        let timeEnd = moment();
        setTimeStart(timeStart);
        setTimeEnd(timeEnd);
    },[])

    const utilitiesClient = useApolloClient('https://api.thecarbonshield.com/utilities/graphql', token);

    const { data: utilitiesData, loading } = useQuery(UTILITIES_QUERY, {
        client: utilitiesClient,
        variables: {buildingUuid:id, utilityType: 'WATER', timeStart, timeEnd}
    });

    const handleTimeRangeChange = (data) => {
        console.log('value changed', data)
        setInitMonth(data);
        if(data=='last3Months') {
            setTimeStart(moment().subtract(2, 'months').startOf('month'));
        } else if(data=='last6Months') {
            setTimeStart(moment().subtract(5, 'months').startOf('month'));
        } else if(data=='lastYear') {
            setTimeStart(moment().subtract(1, 'year').startOf('month'));
        } else if(data=='last3Year') {
            setTimeStart(moment().subtract(3, 'year').startOf('month'));
        }
    };

    useEffect(()=>{
        console.log('utilities', utilitiesData)
        setTotalElectricityRead(utilitiesData?.buildingUtilityConsumption);
    }, [utilitiesData])

    useEffect(()=>{
        const months = [];
        const reads = {};
        totalElectricityRead?.groupedReadings?.map((reading)=>{
            reads[moment(reading.groupName).format('MMM')] = reading.reading?.toLocaleString(2);
        })
        
        let current = moment(timeStart).startOf('month'); // Start from the beginning of the start month

        while (current.isBefore(timeEnd, 'month') || current.isSame(timeEnd, 'month')) {
            // months.push(current.format('MMM')); // You can adjust the format here
            if(!reads[current.format('MMM')]) {
                reads[current.format('MMM')] = 0;
            }
            current.add(1, 'months');
        }
        console.log('cuur',reads);
        let readArr = [];
        for (let key in reads) {
            readArr.push({groupName: key, reading: reads[key]})
        }
        setReadingData(readArr);
    },[totalElectricityRead])


    return (
        <div style={{ height: '100vh' }}>
            { loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Spinner animation="border" role="status"> 
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
                ) :
            (<div className='electryWRapr'>
                <div className='dashprofileWrp'>
                    <div className='container-fluid'>
                        <div className='build_back mb-0'>
                            <a>
                                <Link to={`/building-details/${id}`}>

                                    <svg className='mx-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_3510_24756)">
                                            <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#253338" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3510_24756">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    
                                </Link>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='electGrapWRapper'>
                    <ElectricityCart onTimeRangeChange={handleTimeRangeChange} initMonth={initMonth} totalRead={totalElectricityRead?.total?.reading} electricityData={readings} unit={'gal'} type={'Water'} />
                    <div style={{marginTop:'70px', textAlign:'center', width:'70%', marginLeft:'15%'}} className='table_wrapper'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Reading</th>
                                </tr>
                            </thead>
                            <tbody>
                                {readings?.map((building)=>(
                                    <tr>
                                        <td>
                                            <b>{building.groupName}</b> <br />
                                            {/* <Moment date={building.groupName} format="MMM" /> */}
                                        </td>
                                        <td>                     
                                            {building.reading?.toLocaleString('en-US', { 
                                                minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2 
                                            })} gal
                                        </td>
                                    </tr>
                                ))} 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>)}
        </div>
    )
}

export default WaterDetails
